import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { getSucursales, login } from "../../services/auth.services";
import { errorSetter } from "../../utils";
import { SelectInput, TextInput } from "../Inputs";
import Error from "../Warnings/Error";

const captchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const Login = ({ handleNextStep, handleMainNextStep, activeStep, maxStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const dataset = useSelector((state) => state.config.dataset);
  React.useEffect(() => {
    if (!dataset.operador) {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset]);
  const [recaptchapass, setRecaptchapass] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");
  const [sucursales, setSucursales] = useState(null);
  const [canalSeleccionado, setCanalSeleccionado] = useState("");
  const [show, setShow] = useState(true);
  const onFocusOut = async () => {
    setSucursales([]);
    setCanalSeleccionado("");
    dispatch({ type: "show/spinner" });
    getSucursales(correo)
      .then((response) => {
        setSucursales(response.data);
        dispatch({ type: "show/spinner" });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "hide/spinner" });
      });
  };
  const handleKey = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      onFocusOut();
    }
  };
  const handleSucursal = (sucursal) => {
    setSucursalSeleccionada(sucursal);
    if (sucursal.solicitudTipo === "YA_FIRMADA") {
      setCanalSeleccionado("FISICO");
    }
    if (sucursal.id.toString() === "3") {
      setCanalSeleccionado("SOLICITUD_WEB");
    }
  };
  const handleSubmit = (e) => {
    setShow(false);
    e.preventDefault();
    dispatch({ type: "show/spinner" });
    login(correo, password, sucursalSeleccionada.id)
      .then((response) => {
        dispatch({
          type: "operador/datos",
          payload: {
            sucursal: sucursalSeleccionada,
            mail: correo,
            token: response.data
              ? response.data.auth
                ? response.data.auth
                : response.headers.authorization
              : response.headers.authorization,
            canal: "CONTACT_CENTER_ENTRANTE"
              // sucursalSeleccionada.solicitudTipo === "YA_FIRMADA"
              //   ? "FISICO"
              //   : canalSeleccionado,
          },
        });
        dispatch({ type: "sucursal", payload: sucursalSeleccionada.id });
        dispatch({ type: "hide/spinner" });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
      })
      .catch((error) => {
        dispatch({ type: "hide/spinner" });
        setShow(true);
        if (badLogin(setOpenError, setErrorText, error)) return;
        errorSetter(setOpenError, setErrorText, error);
      });
  };

  function badLogin(setOpenError, setErrorText, error) {
    let err;
    if (error.response.status === 403)
      err = "Usuario y/o contraseña incorrecta";
    if (error.response.status === 423)
      err = "Usuario bloqueado, contacte al administrador";
    if (err) {
      setOpenError(true);
      setErrorText(err);
    }
    return !!err;
  }

  return (
    !loading && (
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormControl required>
          <TextInput
            id="usuario"
            name="usuario"
            label="Usuario"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            onBlur={() => onFocusOut()}
            onKeyDown={(e) => handleKey(e)}
          />
        </FormControl>
        <FormControl required>
          <TextInput
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            autoComplete="current-password"
            required
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => handleKey(e)}
          />
        </FormControl>
        {sucursales && sucursales.length > 0 && (
          <FormControl required sx={{ width: "100%" }}>
            <SelectInput
              onChange={(e) => handleSucursal(e.target.value)}
              id="sucursal"
              name="sucursal"
              label="Sucursal"
              value={sucursalSeleccionada}
              items={sucursales.map((sucursal, index) => (
                <MenuItem key={index} value={sucursal}>
                  {sucursal.nombre}
                </MenuItem>
              ))}
            />

            {sucursalSeleccionada !== "" &&
              sucursalSeleccionada.id.toString() !== "3" &&
              sucursalSeleccionada.solicitudTipo !== "YA_FIRMADA" && (
                <Box
                  sx={{
                    margin: "2rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    onChange={(e) => setCanalSeleccionado(e.target.value)}
                  >
                    <FormControlLabel
                      value="CONTACT_CENTER_ENTRANTE"
                      control={<Radio color="highlight" />}
                      label="Llamada entrante"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="CONTACT_CENTER_SALIENTE"
                      control={<Radio color="highlight" />}
                      label="Llamada saliente"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </Box>
              )}

            {!dataset.operador && dataset.recaptcha && (
              <Box
                display="flex"
                sx={{
                  marginTop: "1rem",
                }}
                flexDirection="row"
                justifyContent="flex-end"
              >
                <ReCAPTCHA
                  sitekey={captchaKey}
                  onChange={(e) => setRecaptchapass(e)}
                />
              </Box>
            )}

            <Button
              disabled={
                show
                  ? !dataset.operador
                    ? dataset.recaptcha
                      ? recaptchapass === null
                      : false
                    : password !== "" &&
                      correo !== "" &&
                      canalSeleccionado !== ""
                    ? false
                    : true
                  : true
              }
              type="submit"
              variant="contained"
              color="button"
              sx={{
                marginTop: "1rem",
                width: "min-content",
                alignSelf: "flex-end",
              }}
            >
              Continuar
            </Button>
          </FormControl>
        )}
        <Error text={errorText} show={openError} setShow={setOpenError} />
      </Box>
    )
  );
};

export default Login;
